<template>
  <div id="LatestJudgement" class="m-5 table-data" >
      <b-table
                  :data="data"
                  :paginated="isPaginated"
                  :per-page="perPage"
                  :current-page.sync="currentPage"
                  @click="clickedTable()"
                  aria-current-label="Current page">
                   <b-table-column field="appellants[0].Appellant" label="Appellants Vs Respondents	"  sortable  v-slot="props">
                        {{ props.row.appellants[0].Appellant }}   <b> Vs </b>
                        {{ props.row.respondents[0].Respondent }}
                         {{ props.row.judgement[0].jID }}

                    </b-table-column>
                   <b-table-column field="judgement[0].DOJ" label="Date"  width="140" sortable  v-slot="props">
                        {{ props.row.judgement[0].DOJ }}
                    </b-table-column>
                   <b-table-column field="appellants[0].Appellant" label="Presiding"  sortable  v-slot="props">
                        <span v-for="judge in  props.row.judges"   v-bind:key="judge.id">
                          {{ judge.Judge }}
                          </span>
                    </b-table-column>
                   <b-table-column field="appellants[0].Appellant" label="INSC Citation"  width="140" sortable  v-slot="props">
                         <span v-for="judge in  props.row.citations"   v-bind:key="judge.id">
                          {{ judge.Citation }}
                          </span>
                    </b-table-column>
                   <b-table-column field="appellants[0].Appellant" label="Follow Ins"  width="50" sortable  v-slot="props">
                        {{ props.row.judgement[0].numFollowIns }}
                    </b-table-column>
                   <b-table-column field="appellants[0].Appellant" label="Follow Ins"  width="100" sortable  v-slot="props">
                        {{ props.row.judgement[0].numFollowIns }}
                    </b-table-column>
                   <b-table-column field="appellants[0].Appellant" label="Follow Ups"  width="120" sortable  v-slot="props">
                            {{ props.row.judgement[0].numFollowUps }}
                    </b-table-column>
                   <b-table-column field="appellants[0].Appellant" width="200" label="Action"  >
                          <b-button> <img src="@/assets/images/bookmark.png"> </b-button>
                         <b-button> <img src="@/assets/images/download.png">  </b-button>
                        <b-button><img src="@/assets/images/view.png">  </b-button>
                        <b-button  class="mt-2">  <img src="@/assets/images/ink.png">  </b-button>
                         <b-button class="mt-2"><img src="@/assets/images/translate.png">  </b-button>
                        <b-button  class="">  <img src="@/assets/images/ink.png">  </b-button>
                         <b-button class=""><img src="@/assets/images/translate.png">  </b-button>
                    </b-table-column>

      </b-table>
  </div>
</template>
<script>
const axios = require('axios');
export default {
  name: 'LandMark',
  data() {
            return {
                isPaginated: true,
                  isPaginationSimple: false,
                  isPaginationRounded: false,
                  paginationPosition: 'bottom',
                  defaultSortDirection: 'asc',
                  sortIcon: 'arrow-up',
                  sortIconSize: 'is-small',
                  currentPage: 1,
                  perPage: 50,
                  data: [],
            }
            },
    mounted (){
          this.getLatestJudgement();
    },

    methods:{
      /*
      getLandmarkJudgements.php
      */
      getLatestJudgement() {
        const loadingComponent = this.$buefy.loading.open({
                    container: this.isFullPage
        })
        axios.get('https://staging.spotlawapp.com/newapi/getLandmarkJudgements.php').then(res => {
             this.data = res.data.data;
             loadingComponent.close();
        })
      },
      clickedTable(){
        console.log('clickedTable');
      }
    }
}
</script>

